import React, { useContext, useEffect, useState } from "react";
import Tabs from "../../components/account/Tabs";
import { useParams } from "react-router-dom";
import NotFound from "../NotFound";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import PowerBI from "../../components/powerBIAuth/PowerBI";
import { UserContext } from "../../context/userContext";
import { breadCrumbs, commissionIntelligence } from "../../data/data";
import CommissionTool from "../../components/modules/CommissionIntelligence/CommissionTool";
import DocumentSummary from "../../components/modules/CommissionIntelligence/DocumentSummary";
import Observations from "../../components/modules/CommissionIntelligence/Observations";

const Summary = ({ solution, deployment }) => {
  const { slug } = useParams();
  const [isSlugCorrect, setIsSlugCorrect] = useState(false);
  const [pageData, setPageData] = useState(null);
  const { setPageTitle, userActivityTracker } = useContext(UserContext);
  const [crumbs, setCrumbs] = useState(null);
  const [modules, setModules] = useState(null);

  useEffect(() => {
    solution.deployment2024.map((module) => {
      if (module?.slug.slice(1) === slug) {

        setModules(solution.deployment2024);
        setPageData(module);
        return setIsSlugCorrect(true);
      }
    });
  }, [slug]);

  useEffect(() => {
    setPageTitle(`${commissionIntelligence.title}: ${pageData?.subtitle}`);
    userActivityTracker(
      "Page changed",
      `${commissionIntelligence.title}: ${pageData?.subtitle}`
    );
    setCrumbs([
      ...breadCrumbs(solution),
      modules?.title !== solution?.title
        ? { title: modules?.title, title: pageData?.title }
        : { title: pageData?.title },
    ]);
  }, [pageData, deployment]);

  return (
    <>
      {/* Timeline Agnostic Template Only */}
      {isSlugCorrect && pageData ? (
        <section className="p-5 pt-0 w-full md:min-h-[35rem]">
          <div className="flex flex-col md:flex-row justify-between">
            <Breadcrumbs crumbs={crumbs} />
          </div>
          <div className="my-5">
            <Tabs data={modules} deployment={solution.slug + deployment.slug} />
          </div>
          <div className={`w-full pb-10 h-full md:min-h-[35rem]`}>
            {/* Sales Prophet 2023 deployments */}
            {pageData?.title === "Commission Tool" && <CommissionTool />}
            {pageData?.title === "Documents Summary" && <DocumentSummary />}
            {pageData?.title === "Observations" && <Observations />}
          </div>
        </section>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default Summary;
