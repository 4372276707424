// ***************************************************************   All Icons Import  ***************************************************************
import { AiOutlineFileText } from "react-icons/ai";
import { dashboards } from "./dashboards";
import { FaGlobeAmericas, FaUserFriends } from "react-icons/fa";
import {
  FaAddressCard,
  FaUserSecret,
  FaMagnifyingGlassDollar,
  FaUserTie,
  FaCalculator,
} from "react-icons/fa6";
import { GoHomeFill } from "react-icons/go";

import { BiDetail } from "react-icons/bi";

// ***************************************************************   All Industries  ***************************************************************
export const deployment2024 = {
  title: "2024 Deployment",
  slug: "",
  name: "deployment2024",
};

// ***************************************************************   All Solutions  ***************************************************************
// Sales Prophet
export const commissionIntelligence = {
  title: "Commission Intelligence",
  slug: "/commission-intelligence",
  // slug: "#",
  icon: <FaGlobeAmericas />,
  description: "",
  deployments: [deployment2024],
  deployment2024: [
    {
      title: "Commission Tool",
      subtitle: "Summary",
      icon: <FaCalculator />,
      slug: "/commission-tool",
      description:
        "Efficiently adjust contingent commissions across all carriers ensuring ongoing refinement with each new contract",
    },
    {
      title: "Documents Summary",
      subtitle: "Documents Summary",
      icon: <AiOutlineFileText />,
      slug: "/document-summary",
      description:
        "",
    },
    {
      title: "Observations",
      subtitle: "Observations",
      icon: <BiDetail />,
      slug: "/observations",
      description:
        "",
    },
  ],
};

// Breadcrumbs
export const breadCrumbs = (item) => [
  {
    title: "Home",
    url: "/home",
  },
  {
    title: item.title,
    url: item.slug,
  },
];

// ***************************************************************  Solutions in Home  ***************************************************************
export const home = [commissionIntelligence];

// ***************************************************************   Menu  ***************************************************************
export const menu = [
  {
    title: "Home",
    slug: "/home",
    icon: <GoHomeFill />,
  },
  ...home,
];
