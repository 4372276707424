import React from "react";

const Observations = () => {
  return (
    <div className="">
      <div className="border shadow w-full">
        <div className="px-2 py-4 bg-primary text-white font-bold text-base">
          Observations
        </div>
        <div className="my-4 mx-6">
          <p className="my-2">
            In addition to the capture and interpretation of all tabular data:{" "}
          </p>
          <ul className="list-disc pl-8 space-y-4">
            <li>
              The engine used the effective date for AMWINS as specified in the
              addendum as January 1, 2023, contract sign date as July 31, 2023 –
              which is later of the two signatures. While the Hanover addendum
              did not specify an effective date, the algorithm interpreted the
              effective date of Hanover’s addendum as January 1, 2024 from text
              “Calendar Year 2024”
            </li>
            <li>
              The engine captured premium growth threshold of less than -10% and
              50% discount factor specified in the text of Hanover Addendum
            </li>
            <li>
              The algorithm captured agency info (IDs, name, address) from
              Hanover, but was de-prioritized given lack of premium and loss
              data from the same agencies and for ease of comparison with AMWINS
              contract
            </li>
            <li>
              Scenario input: The algorithm was provided:
              <ul className="list-circle ml-6 my-2 space-y-1">
                <li>
                  User input of $100M premium in commercial lines, 15% growth,
                  and 45% loss ratio
                </li>
                <li>
                  Assumption of 20%/80% split between New Business and Renewals
                  (soft input)
                </li>
                <li>No assumptions on retention rate</li>
              </ul>
            </li>
            <li>
              Other information usually available (but not provided) to further
              refine the output would be on the portal, such as:
              <ul className="list-circle ml-6 my-2 space-y-1">
                <li>
                  Original plan/agreement associated with the addenda provided
                </li>
                <li>
                  Premiums and loss ratios over 3 years in aggregate to compute
                  growth and bonus factors for more accurate estimate of
                  contingent commission
                </li>
                <li>Product lines and exclusions</li>
                <li>
                  Geographic and Agency level production information for
                  variance analysis
                </li>
                <li>
                  Core commissions to estimate additional contribution of
                  contingent commission and identify product/regions/agencies
                  with greater volatility (input into forecasting)
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Observations;
