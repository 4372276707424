import React from "react";

const DocumentSummary = () => {
  return (
    <div className="">
      <div className="border shadow w-full">
        <div className="px-2 py-4 bg-primary text-white font-bold text-base">
          Documents Summary
        </div>
        <div className="my-4 mx-6">
          <ul className="list-disc pl-4 space-y-4">
            <li>
              Two contract samples were received:
              <ul className="list-decimal list-inside ml-6 my-2 space-y-1">
                <li>AMWINS supplemental compensation agreement addendum</li>
                <li>Hanover CVG 2024 agreement</li>
              </ul>
            </li>
            <li>
              Both documents are addenda with no full/principal plan associated
              with the addenda
            </li>
            <li>
              Both documents are considered as commercial lines without
              specifics on product lines, eligibility or exclusions (e.g.
              industry, geography)
            </li>
            <li>
              Both documents contain tabular as well as text information
              pertinent to summarization
            </li>
            <li>
              AMWINS addendum has specified effective date, new business and
              renewals, but no loss-related incentives
            </li>
            <li>
              Hanover addendum has specified premium growth and limited loss
              ratio ranges, and 9 Broadstreet agencies, but no effective date,
              and premium growth rates and discount factor were embedded in text
              body (not tabular)
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DocumentSummary;
