import React, { useEffect, useState } from "react";
import API from "../../../api/api";
import { LuLoader2 } from "react-icons/lu";
import {
  CI_columns,
  CI_filterInputs,
  CI_formInputs,
  outputFields,
} from "../../../data/modules/commissionIntelligence";
import { FaFilePdf, FaList, FaXmark } from "react-icons/fa6";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { RiSearchLine } from "react-icons/ri";
import {
  CurrencyFormatter,
  NumberFormatter,
  DateFormatter,
  preventScroll,
  PercentFormatter,
  allowScroll,
  makeInt,
} from "../../utils/Utils";
import { colourStyles } from "../../utils/Functions";
import Select from "react-select";
import { createOptions } from "../../../data/staticData";
import TablePaginate from "../../utils/TablePaginate";

const CommissionTool = () => {
  const [inputFormData, setInputFormData] = useState(CI_formInputs);
  const [inputFilterData, setInputFilterData] = useState(CI_filterInputs);
  //   const [inputFilterData, setInputFilterData] = useState();
  const [error, setError] = useState(false);
  const [load, setLoad] = useState(false);
  const [outputData, setOutputData] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [contractTable, setContractTable] = useState(null);
  const [overallContractData, setOverallContractData] = useState(null);
  const [tableLoad, setTableLoad] = useState(false);
  const [carriers, setCarriers] = useState(null);
  const [page, setPage] = useState(1);
  const [showEntries, setShowEntries] = useState(20);
  const [openUpload, setOpenUpload] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadPct, setUploadPct] = useState({ text: "", number: 0 });
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [uploadedContracts, setUploadedContracts] = useState([]);

  const getContractData = async () => {
    if (uploadedContracts.length > 0) {
      setTableLoad(true);
      const carriers = [...new Set(uploadedContracts)];
      // setUploadedContracts([...carriers])
      API.post("/broadstreet/get-contracts", { data: carriers })
        .then(({ data }) => {
          setContractTable(data["contractData"]);
          setOverallContractData(data["contractData"]);
          setCarriers(createOptions(data["carriers"]));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setTableLoad(false);
        });
    }
  };
  console.log(tableLoad);

  useEffect(() => {
    getContractData();
  }, [uploadedContracts]);

  // Input handling on change
  const handleOnChangeInput = (e) => {
    if (e.target.name === "lossRatio") {
      let numericValue = e.target.value.replace(/[^0-9]/g, "");
      numericValue = numericValue.replace(/^([0-9]\d{3})/, "");
      // Format the number with commas for thousands
      const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // Add the percentage sign, unless the field is empty or only contains a negative sign
      const finalValue =
        numericValue.length > 0 && numericValue !== "-"
          ? `${formattedValue}`
          : numericValue;

      setInputFormData({
        ...inputFormData,
        [e.target.name]: finalValue,
      });
    } else if (e.target.name === "ewp") {
      const rawValue = e.target.value;
      let numericValue = rawValue.replace(/[^\d.]/g, "");
      // Format the number with commas
      const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // Add the dollar sign back, unless the field is empty
      const finalValue =
        rawValue.length > 1 ? `${formattedValue}` : numericValue;
      setInputFormData({
        ...inputFormData,
        [e.target.name]: finalValue,
      });
    } else if (e.target.name === "growthRate") {
      let numericValue = e.target.value;
      // Check if the value is negative
      const isNegative = numericValue.startsWith("-");
      // Remove all non-numeric characters except the negative sign and decimal point
      numericValue = numericValue.replace(/[^0-9.]/g, "");
      // Remove leading digits greater than 5 if they exist after the negative sign
      if (isNegative) {
        numericValue = "-" + numericValue.replace(/^-?([0-9]\d{3})/, "");
      } else {
        numericValue = numericValue.replace(/^([0-9]\d{3})/, "");
      }
      // Format the number with commas for thousands
      const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      // Add the percentage sign, unless the field is empty or only contains a negative sign
      const finalValue =
        numericValue.length > 0 && numericValue !== "-"
          ? `${formattedValue}`
          : numericValue;

      setInputFormData({
        ...inputFormData,
        [e.target.name]: finalValue,
      });
    }
  };

  const generateCommission = (e) => {
    e.preventDefault();
    setLoad(true);
    setError(null);
    // console.log(inputFormData);
    if (
      inputFormData.growthRate === 0 ||
      inputFormData.llr === 0 ||
      inputFormData.ewp === 0
    ) {
      setLoad(false);
      return setError("All fields are mandatory!");
    }

    const carriers = [...new Set(uploadedContracts)];

    API.post("/broadstreet/commission-tool", {
      data: inputFormData,
      carriers: carriers,
    })
      .then((response) => {
        const { data } = response;
        // console.log(data);
        // setOutputData(data);
        const allCommissions = data["allData"];
        const sortedCommission = allCommissions.sort(
          (a, b) => b["contingent_commission"] - a["contingent_commission"]
        );
        console.log(sortedCommission);

        setOutputData(sortedCommission);
      })
      .catch((error) => {
        setError(error?.response?.data?.errorResponse);
        setOutputData(null);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const resetForm = () => {
    setInputFormData(CI_formInputs);
    setOutputData(null);
  };

  // Handle change of entries
  const handleEntriesChange = (e) => {
    if (page > 1) {
      setPage(1);
    }
    setShowEntries(e.value);
  };

  // Handle carrier change
  const handleCarrierChange = (e) => {
    setInputFilterData({
      ...inputFilterData,
      carrier: e.value,
    });

    if (e.value === "All Carriers") {
      setContractTable([...overallContractData]);
      return;
    }

    const carrier = e.value;
    const contractData = [...overallContractData];
    const filteredData = contractData.filter(
      (object) => object["Carrier/Partner"] === carrier
    );
    setContractTable(filteredData);
  };

  // Search Handle
  const handleSearchFor = (e) => {
    setInputFilterData({
      ...inputFilterData,
      searchFor: e.target.value,
    });

    const searchItem = e.target.value.toLowerCase();
    const overallData = overallContractData.filter((object) => {
      if (inputFilterData.carrier === "All Carriers") return object;

      return object["Carrier/Partner"] === inputFilterData.carrier;
    });

    const filteredData = overallData.filter((object) => {
      return Object.values(object).some((value) =>
        value.toString().toLowerCase().includes(searchItem)
      );
    });

    setContractTable(filteredData);
  };

  // sort table
  const sortByColumn = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const carrierData = [...contractTable];
    const sortedCarrierData = carrierData.sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }

      return 0;
    });

    setContractTable(sortedCarrierData);
    setSortConfig({ key, direction });
  };

  // get Column Direction
  const getColumnDirection = (key) => {
    if (!sortConfig) {
      return;
    }

    return sortConfig.key === key ? sortConfig.direction : undefined;
  };

  // close upload modal
  const closeUploadModal = () => {
    setOpenUpload(false);
    allowScroll();
    setFileData(null);
    setUploading(false);
  };

  // handle file info
  const handleFileInfo = (e) => {
    let fileName = e.target.value.split("\\");
    setFileData(fileName[fileName.length - 1]);
  };

  // handle upload file
  const handleFileUpload = (e) => {
    e.preventDefault();

    if (fileData) {
      setUploading(true);

      let max = 20;
      let time = 50;
      uploadPct.text = "Uploading the contract";

      const loading = () => {
        setUploadPct({
          ...uploadPct,
          number: (uploadPct.number += 1),
        });
        if (uploadPct.number === max) {
          clearInterval(animate);
        }
      };

      const animate = setInterval(() => loading(), time);

      setTimeout(() => {
        max = 43;
        time = 100;
        uploadPct.text = "Searching for contract";
        const loading = () => {
          setUploadPct({
            ...uploadPct,
            number: (uploadPct.number += 1),
          });
          if (uploadPct.number === max) {
            clearInterval(animate);
          }
          const animate = setInterval(() => loading(), time);
        };
      }, 1000);

      // PATCH API call to update the date
      setTimeout(() => {
        max = 85;
        time = 140;
        uploadPct.text = "Extracting the data";

        // API.patch("/commission-intelligence/commission-tool")
        //   .then((res) => {
        //     // inputFilterData.carrier = "Liberty";
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });

        const loading = () => {
          setUploadPct({
            ...uploadPct,
            number: (uploadPct.number += 1),
          });
          if (uploadPct.number === max) {
            clearInterval(animate);
          }
        };

        const animate = setInterval(() => loading(), time);
      }, 4000);

      setTimeout(() => {
        max = 100;
        time = 130;
        uploadPct.text = "Updating the database";

        const loading = () => {
          if (uploadPct.number < 100) {
            setUploadPct({
              ...uploadPct,
              number: (uploadPct.number += 1),
            });
          } else {
            clearInterval(animate);
          }
          inputFilterData.isFileUploaded = true;
          inputFormData.isFileUploaded = true;
          // getContractData();

          if (uploadPct.number >= max) {
            closeUploadModal();
            clearInterval(animate);
            setUploadPct({ text: "", number: 0 });
            if (fileData.toLowerCase().includes("amwins")) {
              setUploadedContracts((prev) => [...prev, "Amwins"]);
            } else if (fileData.toLowerCase().includes("hanover")) {
              setUploadedContracts((prev) => [...prev, "Hanover"]);
            }
          }
        };
        const animate = setInterval(() => loading(), time);
      }, 10000);
    }
  };

  return (
    <section className="w-full relative">
      {/* Slider Tabs */}
      <div className="flex justify-end">
        <div className="flex w-fit -mt-2 p-1 border shadow text-xs font-semibold rounded-lg bg-gray-100">
          <button
            className={`p-2 rounded-lg from-gray-600 via-gray-700 to-gray-800 ${
              toggle ? "" : "bg-gradient-to-r text-white"
            }`}
            onClick={() => setToggle(false)}
          >
            Commission Scenarios
          </button>
          <button
            className={`p-2 rounded-lg from-gray-600 via-gray-700 to-gray-800 ${
              toggle ? "bg-gradient-to-r text-white" : ""
            }`}
            onClick={() => setToggle(true)}
          >
            Contract Database
          </button>
        </div>
      </div>

      {toggle ? (
        contractTable === null && uploadedContracts.length > 0 ? (
          <div className="p-10 mt-10 gap-2 py-3 flex items-center justify-center w-full">
            Loading Contracts
            <span className="animate-spin text-2xl">
              <LuLoader2 />
            </span>
          </div>
        ) : (
          <section>
            {/* Title */}
            <h2 className="text-base font-semibold">
              Table with existing contracts
            </h2>
            {/* Table filters */}
            <div className="flex justify-between my-5 text-[11px]">
              {/* Filter Selects */}
              <div className="flex justify-between w-full gap-10 items-center h-fit">
                <div className="flex gap-10">
                  {/* Show number of Entries at a time filter */}
                  <div className="flex gap-2 items-center h-fit">
                    Show entries:
                    <Select
                      className="w-20"
                      classNamePrefix="select"
                      isSearchable={true}
                      name="underwriter"
                      options={[
                        { value: "20", label: "20" },
                        { value: "40", label: "40" },
                        { value: "60", label: "60" },
                        { value: "80", label: "80" },
                        { value: "100", label: "100" },
                      ]}
                      onChange={(e) => handleEntriesChange(e)}
                      defaultValue={{
                        value: "20",
                        label: "20",
                      }}
                      styles={colourStyles}
                    />
                  </div>
                  {/* Select Carriers filter */}
                  {carriers !== null && (
                    <div className="flex gap-2 h-fit items-center w-64">
                      Select Carrier/Partner:
                      <Select
                        className="w-40"
                        classNamePrefix="select"
                        // // isSearchable={true}
                        name="carriers"
                        options={[
                          { value: "All Carriers", label: "All Carriers" },
                          ...carriers,
                        ]}
                        onChange={(e) => handleCarrierChange(e)}
                        defaultValue={{
                          value: "All Carriers",
                          label: "All Carriers",
                        }}
                        styles={colourStyles}
                      />
                    </div>
                  )}
                </div>
                <div className="flex gap-10 relative">
                  {/* Upload Contract */}
                  <button
                    onClick={() => {
                      setOpenUpload(true);
                      preventScroll();
                    }}
                    className="flex gap-2 items-center text-sm px-5 rounded-lg text-white bg-tertiary hover:bg-opacity-80"
                  >
                    <AiOutlineCloudUpload className="text-xl" />
                    Upload Contract
                  </button>
                  {/* Search Filter */}
                  <div className="relative h-fit">
                    <RiSearchLine className="absolute z-5 top-3 left-3 w-fit text-gray-400 pr-2 text-lg border-r border-gray-400" />
                    <input
                      type="text"
                      name="searchFor"
                      id="searchFor"
                      placeholder="Search Table.."
                      className="rounded-lg indent-10 px-2 py-3 border focus:outline-2 w-56 focus:outline-gray-200"
                      onChange={(e) => handleSearchFor(e)}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Contracts Table */}
            {contractTable ? (
              <>
                <div className="shadow border-l border-r overflow-x-auto mt-6">
                  <table className="w-full text-[11px] text-gray-500 transition-all duration-300 ease-in-out">
                    <thead className="capitalize bg-gray-300 text-gray-700">
                      <tr className="p-2 transition-all duration-300 ease-in-out text-center">
                        {CI_columns.map((column, index) => (
                          <th
                            key={index}
                            scope=""
                            className="p-1 transition-all duration-300 ease-in-out"
                          >
                            <div
                              className={`w-32 flex p-2 gap-1 justify-center relative cursor-pointer hover:underline underline-offset-2 ${
                                getColumnDirection(column) && "text-tertiary"
                              } `}
                              onClick={() => sortByColumn(column)}
                            >
                              {column}
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {contractTable
                        ?.slice(showEntries * (page - 1), page * showEntries)
                        .map((item, index) => (
                          <tr
                            key={index}
                            className={`border-b hover:bg-gray-200 break-words text-center ${
                              index % 2 === 0 ? "" : "bg-gray-50"
                            }`}
                          >
                            {CI_columns.map((column, id) => {
                              let value;

                              if (
                                column.includes("Loss Ratio") ||
                                column.includes("Growth") ||
                                column.includes("Bonus") ||
                                column.includes("Business") ||
                                column.includes("Renewals")
                              ) {
                                if (item[column] === "-") {
                                  value = item[column];
                                } else if (
                                  column === "Premium Growth Factor Total"
                                ) {
                                  value = PercentFormatter(item[column], 2);
                                } else if (column === "Bonus Factor") {
                                  value = PercentFormatter(item[column], 2);
                                } else if (
                                  column ===
                                  "New Business Premium Growth Factor"
                                ) {
                                  return item[column];
                                } else if (
                                  column === "Renewals Premium Growth Factor"
                                ) {
                                  return item[column];
                                } else {
                                  value = PercentFormatter(item[column]);
                                }
                              } else if (column === "Ingestion Date") {
                                value = DateFormatter(new Date());
                              } else {
                                value = item[column];
                              }

                              return (
                                // On click open chart showing percentile
                                <td
                                  key={id}
                                  className={`p-2 ${
                                    item[column] === "Liberty"
                                      ? "text-sky-700 font-semibold"
                                      : ""
                                  }`}
                                >
                                  {value}
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                    </tbody>
                    {tableLoad && (
                      <tbody className="absolute topx-9 py-36 bg-black bg-opacity-30 text-white text-lg flex gap-2 items-center justify-center z-10 h-full w-full">
                        <tr className="w-full">
                          <td className="animate-spin text-xl">
                            <LuLoader2 />
                          </td>
                          <td>Loading..</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>

                {/* Show when no rows available */}
                {contractTable.length === 0 && (
                  <div className="w-full flex justify-center p-2 text-center border shadow text-xs text-gray-600">
                    No matching records found
                  </div>
                )}
                {/* Table Pagination */}
                <TablePaginate
                  setPage={setPage}
                  showEntries={showEntries}
                  page={page}
                  table={contractTable}
                />
              </>
            ) : (
              <>
                <div className="shadow rounded-md p-7 mt-10 border">
                  <h2>Please upload a contract</h2>
                </div>
              </>
            )}

            {/* upload pdf file modal */}
            {openUpload && (
              <>
                <div
                  className="fixed z-20 top-0 left-0 h-screen w-screen bg-black bg-opacity-20"
                  onClick={() => load === false && closeUploadModal()}
                ></div>
                {/* {console.log(caseScores)} */}
                <div className="fixed z-30 flex items-center w-1/2 justify-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                  <div className="bg-white px-16 py-10 rounded-lg relative flex w-full gap-10 items-center justify-center">
                    <div
                      className="absolute top-5 right-5 cursor-pointer bg-gray-50 hover:bg-gray-200 px-2 py-1 rounded-lg"
                      onClick={() => uploading === false && closeUploadModal()}
                    >
                      <FaXmark />
                    </div>
                    <form action="" className="h-fit relative w-full">
                      <div
                        className={`h-[300px] relative w-full border-[3.5px] border-dashed select-none ${
                          uploading
                            ? ""
                            : "cursor-pointer hover:border-primary bg-gray-100 hover:bg-gray-200"
                        }  rounded-md flex items-center justify-center `}
                      >
                        {fileData ? (
                          <>
                            {uploading && (
                              <video
                                src="https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/modules/commission-intelligence/uploader.mp4"
                                className="outline-none w-[200px]"
                                playsInline
                                autoPlay
                                muted
                              ></video>
                            )}
                            <div
                              className={`absolute flex items-center justify-center gap-2 ${
                                uploading ? "bottom-5" : ""
                              }`}
                            >
                              <FaFilePdf className="text-2xl" />
                              {fileData}
                            </div>
                          </>
                        ) : (
                          <p className="text-gray-400 absolute">
                            Drag and drop pdf contract file here..
                          </p>
                        )}
                        {!uploading && (
                          <input
                            type="file"
                            name="file"
                            id="file"
                            className="w-full h-full opacity-0 cursor-pointer"
                            accept="application/pdf"
                            onChange={(e) => handleFileInfo(e)}
                          />
                        )}
                      </div>
                      {/* Actions */}
                      <div className="mt-3">
                        {uploading ? (
                          <>
                            <div className="w-full h-4 bg-gray-400 rounded-full">
                              <div
                                className="h-4 rounded-full transition-all ease-in-out bg-gradient-to-r from-gray-600 via-gray-700 to-gray-800"
                                style={{ width: uploadPct?.number + "%" }}
                              ></div>
                            </div>
                            <p className="text-sm mt-2 text-center w-full">
                              {uploadPct?.text}.. {uploadPct?.number}% complete
                            </p>
                          </>
                        ) : (
                          <input
                            type="submit"
                            value="Upload Contract"
                            className="float-right p-2 cursor-pointer bg-primary hover:bg-opacity-80 rounded-lg text-white"
                            onClick={(e) => handleFileUpload(e)}
                          />
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </>
            )}
          </section>
        )
      ) : (
        <div className="p-5 flex justify-between h-full gap-16 relative">
          <div className="p-7 bg-white rounded-lg shadow border w-fit h-fit text-xs text-gray-600">
            <h2 className="text-base font-bold mb-5">
              Commercial Lines Illustration{" "}
              <span
                className={
                  error === "All fields are mandatory!" ? "text-red-500" : ""
                }
              >
                (Insert All Fields)
              </span>
            </h2>
            <form className="" onSubmit={(e) => generateCommission(e)}>
              {/* Inputs */}
              <div className="flex flex-col gap-5 text-xs">
                {/* Eligible Written Premium */}
                <div className="flex flex-col gap-1 relative">
                  <label className="font-semibold" htmlFor="writtenPremium">
                    Eligible Written Premium (USD):
                  </label>
                  <div className="flex items-center">
                    <span className="rounded-l-md p-[11px] bg-gray-200">$</span>
                    <input
                      type="text"
                      name="ewp"
                      id="ewp"
                      value={inputFormData.ewp || ""}
                      onChange={(e) => handleOnChangeInput(e)}
                      className="border rounded-r-md focus:outline-none px-2 py-[10px] w-full"
                    />
                  </div>
                </div>
                {/* Loss Ratio */}
                <div className="flex flex-col gap-1 relative">
                  <label className="font-semibold" htmlFor="lossRatio">
                    Limited Loss Ratio (%){" "}
                    <sup className="text-red-500 text-sm">*</sup>:
                  </label>
                  <div className="flex">
                    <span className="rounded-l-md p-[10px] bg-gray-200">%</span>
                    <input
                      type="text"
                      name="lossRatio"
                      id="lossRatio"
                      value={inputFormData.lossRatio || ""}
                      onChange={(e) => handleOnChangeInput(e)}
                      maxLength={5}
                      className="border rounded-r-md focus:outline-none px-2 py-[10px] w-full"
                    />
                  </div>
                </div>
                {/* Growth Rate */}
                <div className="flex flex-col gap-1 relative">
                  <label className="font-semibold" htmlFor="lossRatio">
                    Premium Growth Factor (%):
                  </label>
                  <div className="flex">
                    <span className="rounded-l-md p-[10px] bg-gray-200">%</span>
                    <input
                      type="text"
                      name="growthRate"
                      id="growthRate"
                      value={inputFormData.growthRate || ""}
                      onChange={(e) => handleOnChangeInput(e)}
                      maxLength={5}
                      className="border rounded-r-md focus:outline-none px-2 py-[10px] w-full"
                    />
                  </div>
                </div>
              </div>

              {/* Actions */}
              <div className="w-full flex gap-2 my-5 text-sm justify-end mt-10">
                <input
                  type="reset"
                  value="Reset"
                  onClick={() => resetForm()}
                  className="px-4 py-1 bg-gray-200 rounded-md cursor-pointer hover:bg-gray-300"
                />
                {/* Get Score */}
                {load ? (
                  <div className="flex items-center gap-2 px-5 py-2 bg-gray-300 rounded-md">
                    <span className="animate-spin">
                      <LuLoader2 />
                    </span>
                    <span>Processing...</span>
                  </div>
                ) : (
                  <input
                    type="submit"
                    value="Distrubute Bonus"
                    onClick={(e) => generateCommission(e)}
                    className="px-5 py-2 bg-primary rounded-md text-white cursor-pointer hover:bg-opacity-80"
                  />
                )}
              </div>
              <p>
                <sup className="text-red-500 text-sm">*</sup> Adjusted/Limited
                loss ratio in line with stipulated contracts
              </p>
            </form>
          </div>

          {outputData && (
            <div className="p-7 bg-white rounded-lg shadow border w-[60%] text-sm text-gray-600 ">
              <div>
                <h2 className="font-bold text-base mb-4">
                  Supplemental Commission Offered:{" "}
                </h2>
              </div>

              <div className="overflow-hidden">
                <table className="w-full px-4 py-2">
                  <thead>
                    <tr className="bg-tertiary text-white">
                      <th className="px-4 py-2">Rank</th>
                      {outputFields.map((row, index) => (
                        <th
                          className={`px-4 py-2 border ${
                            row.value === "contingent_commission" &&
                            "text-right pr-10"
                          }`}
                          key={index}
                        >
                          {row.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {outputData.map((item, index) => (
                      <tr key={index} className="border text-center text-base">
                        <td className="px-4 py-2">{index + 1}</td>
                        {outputFields.map((row, idx) => (
                          <td
                            key={idx}
                            className={`border ${
                              row.value === "contingent_commission" &&
                              "text-green-700 font-bold text-right pr-10"
                            }`}
                          >
                            {row.value === "contingent_commission"
                              ? CurrencyFormatter(item[row.value])
                              : item[row.value]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {error && (
            <div className="p-7 bg-white rounded-lg shadow border w-2/3 text-sm text-gray-600 flex justify-center items-center">
              <p className="text-red-500">{error}</p>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default CommissionTool;
