export const CI_formInputs = {
  ewp: 0,
  lossRatio: 0,
  growthRate: 0,
};

export const outputFields = [
  {
    label: "Carrier/Partner",
    value: "carrier",
  },
  // {
  //   label: "Agency Name",
  //   value: "Agency Name",
  // },
  // {
  //   label: "Bonus Factor",
  //   value: "bonus_factor",
  // },
  // {
  //   label: "Growth Rate Factor",
  //   value: "growth_rate_factor",
  // },
  {
    label: "Supplemental Commission",
    value: "contingent_commission"
  }
];

// Table inputs
export const CI_filterInputs = {
  carrier: "All Carriers",
  orderBy: "Carrier Name",
  orderStyle: "ASC",
  searchFor: "",
  isFileUploaded: false,
};

export const CI_columns = [
  'Ingestion Date', 
  'Carrier/Partner', 
  'Effective Date', 
  'Exclusions', 
  'Segment', 
  'Product', 
  'Limited Loss Ratio Lower', 
  'Limited Loss Ratio Upper', 
  'Premium Growth Lower', 
  'Premium Growth Upper', 
  'Premium Growth Factor', 
  'New Business', 
  'Renewals', 
  'New Business Premium Growth Lower', 
  'New Business Premium Growth Upper', 
  'New Business Premium Growth Factor', 
  'Renewals Premium Growth Lower', 
  'Renewals Premium Growth Upper', 
  'Renewals Premium Growth Factor', 
  'Bonus Factor', 
  'Premium Growth Factor Total'
]
