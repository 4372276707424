import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../context/userContext";

const image =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/auth/404.webp";

const spinnakerIcon =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-icon-white.webp";

const NotFound = () => {
  const { isUserLoggedIn, setPageTitle } = useContext(UserContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [pageLoad, setPageLoad] = useState(true);

  useEffect(() => {
    setPageTitle("Not Found");
    setTimeout(() => {
      setPageLoad(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("broadstreet_user_info") || isUserLoggedIn) {
      if (
        pathname === "/login" ||
        pathname === "/forgot-password" ||
        pathname === "request-an-account"
      ) {
        navigate("/home");
      }
    } else {
      navigate("/login");
    }
  }, []);

  return pageLoad ? (
    <div className="w-screen h-screen flex justify-center items-center">
      <span className="relative flex h-10 w-10">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-70"></span>
        <img src={spinnakerIcon} className="" alt="" />
      </span>
    </div>
  ) : (
    <section className="fixed top-0 z-50 left-0 h-screen w-screen bg-white font-semibold p-10 gap-5 flex flex-col items-center justify-center">
      <img src={image} alt="404 page not found" className="w-3/5" />
      <p>
        Back to{" "}
        <Link
          to={"/home"}
          className="hover:underline text-blue-500 underline-offset-2"
        >
          home
        </Link>
      </p>
    </section>
  );
};

export default NotFound;
