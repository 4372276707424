import React from "react";

//========================================= Number Operations =========================================

// Number formatter
export const NumberFormatter = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(number);
  return formattedNumber;
};

// Number formatter for currency
export const NumCurrFormatter = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 1,
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(number);
  return formattedNumber;
};

// Number formatter for currency
export const NumFormatterM = (number) => {
  const formattedNumber = (number / 1000000).toFixed(1);
  return formattedNumber + "M";
};

export const NumFormatterK = (number) => {
  const formattedNumber = (number / 1000).toFixed(0);
  return formattedNumber + "K";
};

// Currency $ formatter
export const CurrencyFormatter = (number) => {
  const formattedNumber = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
  return formattedNumber;
};

// Percent Formatter %
export const PercentFormatter = (number, digits=0) => {
  const formattedNumber = (number * 100).toFixed(digits) + "%";
  return formattedNumber;
};

// Percentage Handler when Backspacing
export const handleBackspaceForPercentage = (e, changeStateFunction) => {
  if (e.key === "Backspace") {
    e.preventDefault();

    if (e.target.value === "%") {
      e.target.value = "0%";
      const removePercent = e?.target?.value?.split("%")?.join("");
      const value = parseFloat(removePercent ? removePercent : 0);
      changeStateFunction(e, value);
      return;
    }
    if (
      e.target.value.length > 0 &&
      e.target.value[e.target.value.length - 2] !== "%"
    ) {
      if (e.target.value.slice(0, -2) === "") {
        e.target.value = "0%";
        const removePercent = e?.target?.value?.split("%")?.join("");
        const value = parseFloat(removePercent ? removePercent : 0);
        changeStateFunction(e, value);
        return;
      }
      e.target.value = e.target.value.slice(0, -2) + "%";
      const removePercent = e?.target?.value?.split("%")?.join("");
      const value = parseFloat(removePercent ? removePercent : 0);
      changeStateFunction(e, value);
    }
  }
};

// Format number to plain int
export const makeInt = (number) => {
  const parts = number.split(",").join("");
  return parseFloat(parts);
};

// Divide by 100
export const convertToPercent = (number) => {
  const value = number / 100;
  return value;
};

//========================================= Date Operations =========================================

// Date Fromatter
export const DateFormatter = (date) => {
  const formattedDate = new Intl.DateTimeFormat("en-US").format(date);
  return formattedDate;
};

// function to change date pattern to mm/dd/yyyy
export const changeDate = (date) => {
  const parts = date.split("-");
  return `${parts[1]}/${parts[2]}/${parts[0]}`;
};

//========================================= Miscellaneous Operations =========================================

// Functions created to allow and disallow scroll to work when working on an existing layer over body
const body = document.body;

// prevents scroll in a body
export const preventScroll = () => {
  body.classList.add("overflow-hidden");
  body.classList.remove("overflow-auto");
};

// allows scroll in body
export const allowScroll = () => {
  body.classList.add("overflow-auto");
  body.classList.remove("overflow-hidden");
};

// Get client device width with cursor x and y coordinates
export const getCursorCoordinates = (event) => {
  let x = event.clientX;
  let y = event.clientY;
  let width = Math.max(window.screen.width, window.innerWidth);
  let height = Math.max(window.screen.height, window.innerHeight);
  return { width: width, height: height, x: x, y: y };
};

// function to get element's offset rations
export const getOffset = (el) => {
  const rect = el.target.getBoundingClientRect();
  console.log(rect.width);
  return {
    left: (rect.left + window.scrollX).toFixed(2),
    top: (rect.top + window.scrollY).toFixed(2),
    width: rect.width,
    height: rect.height,
  };
};
